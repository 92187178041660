import type {
    GetStaticPropsContext,
    InferGetStaticPropsType,
    NextPage,
    PreviewData,
} from "next";
import { ParsedUrlQuery } from "querystring";

import { ErrorPageTemplate } from "@/ui/templates/error_page";

import { generatePageLevelProps } from "@/util/data_util";
import { generateLegacyPreFooterData } from "@/util/strapi_data_util";
import { getGlobals } from "@/util/strapi_util";

export const getStaticProps = async ({
    preview: previewMode,
}: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const slug = "404";

    const globalsData = await getGlobals(previewMode);

    return {
        props: {
            ...generatePageLevelProps({ previewMode, slug }),
            ...generateLegacyPreFooterData(globalsData),
            globalsData,
        },
    };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<PageProps> = () => {
    /**
     * Elements
     */
    return <ErrorPageTemplate />;
};

export default Page;
