/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Text } from "@/ui/atoms/text";

export const ErrorPageTemplate: FunctionComponent = () => {
    /**
     * Elements
     */
    return (
        <GridContainer
            marginBottom="DefaultSectionMargin"
            marginTop="DefaultSectionMargin"
        >
            <GridColumn
                className={css({
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                })}
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 4,
                }}
            >
                <Icon
                    className={css({
                        marginBottom: Spacing["spacing-2"],
                    })}
                    color="yellow-40"
                    size={{
                        extraSmall: 32,
                        medium: 40,
                        large: 44,
                        extraLarge: 48,
                    }}
                    slug="Warning Circled"
                    weight="light"
                />

                <Text
                    className={css({
                        marginBottom: Spacing["spacing-2"],
                    })}
                    fontSize="HeadlineHero"
                    tag="h1"
                    textAlign="center"
                >
                    Page not found.
                </Text>

                <Text
                    className={css({
                        marginBottom: Spacing["spacing-8"],
                    })}
                    fontSize="Subheadline"
                    textAlign="center"
                >
                    {`The page you are looking for doesn't exist or has been
                    moved.`}
                </Text>

                <Button fontSize="CtaHero" href="/">
                    Go home
                </Button>
            </GridColumn>
        </GridContainer>
    );
};
